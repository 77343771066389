<!--
 * @Description:
 * @Version: 3.0
 * @Author: Kevin
 * @Date: 2021-04-14 10:05:17
 * @LastEditors: Kevin
-->
<template>
    <div>
        <img src="../../assets/images/home.png" alt="" />
    </div>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {};
        },
        methods: {},
    };
</script>

<style lang="less" scoped>
    img {
        width: 100%;
        height: 100%;
    }
</style>
